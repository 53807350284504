
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  nextTick,
  computed,
} from "vue";
import { SelectFormat } from "@/views/Delay/type";
import {
  DelayApplySearchType,
  StatusListCheck,
  ApplyListCheck,
} from "@/views/DelayCallback/types";
import {
  getOverviewValue,
  getRegionCheckDetailTableData,
  getPicBasic,
  approveSIPOInformation,
  rejectInSIPOformation,
  getDetailInformation,
} from "@/API/delayCallback/index";
import downloadFile from "@/utils/claim/downloadFile";
import NoticeModal from "@/components/NoticeModal.vue";
import { useRouter } from "vue-router";
import setColumnWidth from "@/utils/rv/setColumnWidth";
import base64toBlob from "@/utils/rv/base64toBlob";
import { message } from "ant-design-vue";
import useDelay from "@/hooks/claimDelay/useDelay";
export default defineComponent({
  components: {
    NoticeModal,
  },
  setup() {
    const { spaceSize } = useDelay();
    const activeKey = ref("1");
    const router = useRouter();
    const applyTypeList = ref<SelectFormat[]>([
      { value: "1", title: "delay" },
      { value: "2", title: "call back" },
    ]);
    const statusList = ref<SelectFormat[]>([
      { value: "1", title: "Submitted" },
      { value: "2", title: "Region approved" },
      { value: "3", title: "Region reject" },
      { value: "4", title: "SIPO approved" },
      { value: "5", title: "SIPO reject" },
    ]);

    const tableData = ref([]);
    const tableHeight = ref();
    const columns = [
      {
        title: "Request code",
        width: 150,
        dataIndex: "applyCode",
      },
      {
        title: "Request type",
        width: 120,
        dataIndex: "applyType",
        slots: { customRender: "applyType" },
      },
      {
        title: "Dealer code",
        width: 150,
        dataIndex: "dealerCode",
      },
      {
        title: "Dealer name",
        width: 150,
        dataIndex: "dealerNameEn",
      },
      {
        title: "VIN",
        width: 180,
        dataIndex: "vinNo",
      },
      {
        title: "Retail Date",
        width: 120,
        dataIndex: "retailDate",
      },
      {
        title: "Request Date",
        width: 120,
        dataIndex: "applyDate",
      },
      {
        title: "Status",
        width: 150,
        dataIndex: "status",
        slots: { customRender: "status" },
      },
      {
        title: "Document", //TODO 下载附件
        fixed: "right",
        width: 380,
        dataIndex: "document",
        slots: { customRender: "document" },
      },
      {
        title: "Operation", //TODO 等UI
        fixed: "right",
        width: 100,
        dataIndex: "failReason",
        slots: { customRender: "failReason" },
      },
    ];

    //* 概览数据
    const overviewValue = reactive({
      delayRequset: "",
      delayRequsetApproved: "",
      callBackRequset: "",
      callBackRequsetApproved: "",
    });

    const seachParams = reactive<DelayApplySearchType>({
      applyCode: "",
      applyType: "",
      applyDate: "",
      status: "2",
      dealerCode: "",
      dealerNameEn: "",
      vinNo: "",
      current: 0,
      size: 20,
      dealerNameCn: "",
      regionalManagerCode: "",
      regionalManagerName: "",
      retailDate: "",
    });
    // 表格复选框逻辑
    const state = reactive<any>({
      selectedRowKeys: [], // Check here to configure the default column
      selectedRows: [],
    });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0);

    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 0,
      total: 0,
    });
    //弹窗显示
    const modalVisible = ref<boolean>(false);
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });
    // 填写拒绝原因
    const rejectVisible = ref<boolean>(false);
    const rejectReason = ref<string>("");

    const tabChange = () => {
      if (activeKey.value == "2") {
        router.push({ path: "/claimDelay/SIPOClaim" });
      }
      if (activeKey.value == "3") {
        router.push({ path: "/delayUsedCar/sipoUC" });
      }
    };

    // 刷新表格高度
    const getTableScroll = () => {
      nextTick(() => {
        //  默认底部分页50 + 边距10
        const extraHeight = 120;
        const tHeader = document.getElementsByClassName("table-wrap")[0];
        //表格内容距离顶部的距离
        let tHeaderBottom = 0;
        if (tHeader) {
          tHeaderBottom = tHeader.getBoundingClientRect().top;
        }
        //窗体高度-表格内容顶部的高度-表格内容底部的高度
        const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
        tableHeight.value = height;
        if (!(tableData.value && tableData.value.length > 0)) {
          (
            document.getElementsByClassName("ant-table-placeholder")[0] as any
          ).style.height = `calc(100vh - ${tHeaderBottom + 60}px)`;
        }
      });
    };

    //TODO 概览数据
    const getOverview = () => {
      getOverviewValue().then((res: any) => {
        overviewValue.delayRequset = res.delayApplyCount;
        overviewValue.delayRequsetApproved = res.delayApplyWaitingCount;
        overviewValue.callBackRequset = res.callBackCount;
        overviewValue.callBackRequsetApproved = res.callBackWaitingCount;
      });
    };

    const reset = () => {
      seachParams.applyCode = "";
      seachParams.applyType = "";
      seachParams.applyDate = "";
      seachParams.status = "2";
      seachParams.vinNo = "";
      seachParams.dealerCode = "";
      seachParams.dealerNameEn = "";
      pagination.currentPage = 0;
      pagination.pageSize = 20;
    };

    //TODO 表格数据
    const getTableData = () => {
      seachParams.current = pagination.currentPage;
      seachParams.size = pagination.pageSize;
      getRegionCheckDetailTableData(seachParams).then((res: any) => {
        if (res !== "") {
          tableData.value = res.pageList.content;
          pagination.total = parseFloat(res.pageList.totalElements);
          const customerColumn = ["applyCode", "dealerNameEn"];
          tableData.value.length &&
            Object.assign(
              columns,
              setColumnWidth(columns, tableData.value, customerColumn)
            );
        }
      });
      getTableScroll();
      state.selectedRowKeys = [];
      state.selectedRows = [];
      getOverview();
    };

    const quickSearch = (
      requestTypeCondition: string,
      statusCondition = ""
    ) => {
      reset();
      seachParams.applyType = requestTypeCondition;
      seachParams.status = statusCondition;
      getTableData();
    };

    // 发票/行驶证/证明 查看弹窗
    const pictureModal = reactive({
      pictureModalFlag: false,
      pictureModalTitle: "",
      pictureModalImg: "",
      fileName: "",
      pdf: "",
      base64Blob: {},
      isImage: true,
    });

    const viewPicEvent = (vinNo: string, type: string, dealerCode: string) => {
      if (type == "license") {
        pictureModal.pictureModalTitle = "RE_" + vinNo;
      } else if (type == "invoice") {
        pictureModal.pictureModalTitle = "IN_" + vinNo;
      } else if (type == "prove") {
        pictureModal.pictureModalTitle = "延期证明_" + vinNo;
      } else if (type === "relationProof") {
        pictureModal.pictureModalTitle = "RP_" + vinNo;
      }
      const params = { vinNo, type, dealerCode };
      getPicBasic({ params }).then((res: any) => {
        if (!res) {
          message.error("数据为空");
          return;
        }
        let URL = {};
        pictureModal.fileName = res.docName;
        const fileName = res.docName;
        const fileUrl = res.base64;
        if (fileName.indexOf(".docx") > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(
            fileUrl,
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          );
          pictureModal.pdf = window.URL.createObjectURL(URL);
        } else if (fileName.indexOf(".xlsx") > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(
            fileUrl,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          pictureModal.pdf = window.URL.createObjectURL(URL);
        } else if (fileName.indexOf(".jpg") > -1) {
          pictureModal.isImage = true;
          pictureModal.pictureModalImg = "data:image/jpeg;base64," + res.base64;
          URL = base64toBlob(fileUrl, "image/jpeg");
        } else if (fileName.indexOf(".pdf") > -1) {
          pictureModal.isImage = false;
          URL = base64toBlob(fileUrl, "application/pdf");
          pictureModal.pdf = window.URL.createObjectURL(URL);
        }
        pictureModal.pictureModalFlag = true;
        pictureModal.base64Blob = URL;
      });
    };
    const downloadImg = (pictureModal: any) => {
      const downloadElement = document.createElement("a");
      downloadElement.href = window.URL.createObjectURL(
        pictureModal.base64Blob
      );
      downloadElement.download = pictureModal.fileName;
      downloadElement.click();
    };
    const closePictureModal = () => {
      pictureModal.pictureModalFlag = false;
    };

    const exportExcel = () => {
      const params = {
        url: "./rvapi/region/approve/export",
        method: "post",
        params: seachParams,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };

    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      getTableData();
    };

    const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
    };

    const openModal = (record: any) => {
      getDetailInformation(record.id).then((res: any) => {
        sessionStorage.setItem("DCBSIPOCheckDetailInfo", JSON.stringify(res));
        router.push({ path: "/delayCallback/SIPOCheckRetailDetail" });
      });
    };

    // 关闭信息弹窗
    const closeNoticeModal = (param: {
      isVisible: boolean;
      continue: boolean;
    }) => {
      modalVisible.value = param.isVisible;
    };

    const closeReject = () => {
      rejectVisible.value = false;
      rejectReason.value = "";
    };

    const submitRejectReason = () => {
      if (rejectReason.value && rejectReason.value.trim()) {
        const vinNoArr = ref<any>([]);
        const arr = state.selectedRows;
        arr.forEach((item: any) => {
          vinNoArr.value.push(item.id);
        });
        const params = {
          ids: vinNoArr.value,
          reason: rejectReason.value.trim(),
        };
        rejectInSIPOformation(params).then((res: any) => {
          if (res.code !== "0") {
            modalVisible.value = true;
            modalParams.noticeType = "warning";
            // sessionStorage.setItem("muchContent", JSON.stringify(res.data));
            modalParams.content = res.message;
            modalParams.btnVisible = false;
            Object.assign(modalData, modalParams);
          } else {
            modalVisible.value = true;
            modalParams.noticeType = "success";
            modalParams.content = "SIPO reject";
            modalParams.btnVisible = false;
            Object.assign(modalData, modalParams);
            rejectVisible.value = false;
            rejectReason.value = "";
            getTableData();
          }
        });
      } else {
        modalVisible.value = true;
        modalParams.noticeType = "warning";
        modalParams.content = "Please input the rejection reason.";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      }
    };

    const reject = () => {
      if (state.selectedRowKeys.length == 0) {
        modalVisible.value = true;
        modalParams.noticeType = "warning";
        modalParams.content = "Please select at least one application.";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      } else {
        const vinNoArr = ref<any>([]);
        const arr = state.selectedRows;
        let isApprove = 0;
        arr.forEach((item: any) => {
          if (item.status !== "2") {
            isApprove = 1;
          }
        });
        if (isApprove == 1) {
          modalVisible.value = true;
          modalParams.noticeType = "warning";
          modalParams.content =
            "The application in this status can’t be operated.";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
        } else {
          rejectVisible.value = true;
        }
      }
    };

    const approve = () => {
      if (state.selectedRowKeys.length == 0) {
        modalVisible.value = true;
        modalParams.noticeType = "warning";
        modalParams.content = "Please select at least one application.";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      } else {
        const vinNoArr = ref<any>([]);
        const arr = state.selectedRows;
        let isApprove = 0;
        arr.forEach((item: any) => {
          if (item.status !== "2") {
            isApprove = 1;
          }
        });
        if (isApprove == 1) {
          modalVisible.value = true;
          modalParams.noticeType = "warning";
          modalParams.content =
            "The application in this status can’t be operated.";
          modalParams.btnVisible = false;
        } else {
          arr.forEach((item: any) => {
            vinNoArr.value.push(item.id);
          });
          const params = {
            ids: vinNoArr.value,
          };
          approveSIPOInformation(params).then((res: any) => {
            if (res.code !== "0") {
              modalVisible.value = true;
              modalParams.noticeType = "warning";
              modalParams.content = res.message;
              modalParams.btnVisible = false;
              Object.assign(modalData, modalParams);
            } else {
              modalVisible.value = true;
              modalParams.noticeType = "success";
              modalParams.content = "SIPO approve";
              modalParams.btnVisible = false;
              Object.assign(modalData, modalParams);
              getTableData();
            }
          });
        }
        Object.assign(modalData, modalParams);
      }
    };

    onMounted(() => {
      getOverview();
      getTableData();
    });
    return {
      tableHeight,
      status,
      statusList,
      seachParams,
      tableData,
      columns,
      reset,
      ...toRefs(overviewValue),
      ...toRefs(pagination),
      getTableData,
      pageChange,
      sizeChange,
      exportExcel,
      openModal,
      activeKey,
      getTableScroll,
      pictureModal,
      viewPicEvent,
      downloadImg,
      closePictureModal,
      pagination,
      overviewValue,
      applyTypeList,
      state,
      hasSelected,
      onSelectChange,
      reject,
      approve,
      modalVisible,
      modalData,
      modalParams,
      closeNoticeModal,
      rejectVisible,
      rejectReason,
      submitRejectReason,
      StatusListCheck,
      ApplyListCheck,
      closeReject,
      tabChange,
      spaceSize,
      quickSearch,
    };
  },
});
